<template>
  <div>
    <v-form
      ref="form"
      v-model="valid"
      lazy-validation
      @submit.prevent="save"
    >
      <v-card>
        <v-card-title>
          <span class="headline">Register</span>
        </v-card-title>

        <v-card-text>
          <v-container>
            <v-row>
              <v-col
                cols="12"
              >
                <v-text-field
                  v-model="newUser.name"
                  :rules="[rules.required]"
                  label="Full Name"
                ></v-text-field>
              </v-col>
              <v-col
                cols="12"
              >
                <v-text-field
                  v-model="newUser.email"
                  :rules="emailRules"
                  label="User Email"
                ></v-text-field>
              </v-col>
              <v-col
                cols="12"
              >
                <v-text-field
                  v-model="newUser.password"
                  :append-icon="showPw ? 'mdi-eye' : 'mdi-eye-off'"
                  :rules="[rules.required, rules.min, rules.complex]"
                  :type="showPw ? 'text' : 'password'"
                  name="input-10-1"
                  label="Password"
                  counter
                  @click:append="showPw = !showPw"
                ></v-text-field>
              </v-col>
              <v-col
                cols="12"
              >
                <v-text-field
                  v-model="newUser.phone"
                  label="Phone #"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>

        <v-card-actions>
          <v-btn
            color="blue darken-1"
            @click="gotoLogin"
          >
            Have Account, Sign-in
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn
            color="blue darken-1"
            @click="save"
          >
            Submit
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-form>
    <v-snackbar
      v-model="snackbar"
    >
      {{ snackbarMessage }}

      <template v-slot:action="{ attrs }">
        <v-btn
          color="red"
          text
          v-bind="attrs"
          @click="snackbar = false"
        >
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
  import { Auth, Firestore, Functions } from '@/database/config/firebase'
  import store from '@/store'

  export default {
    name: 'SectionMap',
    data: () => ({
      valid: true,
      newUser: {
        id: '',
        active: true,
        name: '',
        email: '',
        password: '',
        phone: '',
        usertype: 'User',
        disabled: false,
      },
      snackbar: false,
      snackbarMessage: '',
      showPw: false,
      loginError: null,
      rules: {
        required: v => !!v || 'Password is required',
        min: v => (v && v.length >= 8) || 'Name must be at least 8 characters',
        complex: v => (/[A-Z]/.test(v) && /[a-z]/.test(v) && /[0-9]/.test(v) && /[#?!@$%^&*-]/.test(v)) || 'Password Must Contain at least 1 Uppercase, 1 Lowercase, 1 Number, and 1 Special Character',
      },
      email: '',
      emailRules: [
        v => !!v || 'E-mail is required',
        v => /.+@.+\..+/.test(v) || 'E-mail must be valid',
      ],
    }),
    provide: {
      heading: { align: 'center' },
    },
    computed: {
      message () {
        return this.loginError || this.$route.query.message || ''
      },
    },
    mounted () {
      this.initialize()
    },
    methods: {
      initialize () {
        // console.log('to', this.$route.query.returnPath)
      },
      validate () {
        this.$refs.form.validate()
      },
      gotoLogin () {
        this.$router.push('/login').catch(err => {
          console.log('error redirecting : ', err)
        })
      },
      redirectPortal () {
        this.$router.push(this.$route.query.returnPath ? this.$route.query.returnPath : '/vizion/dashboard').catch(err => {
          console.log('error redirecting : ', err)
        })
      },
      save () {
        // Check if user already exists
        this.validate()
        if (this.valid) {
          // console.log(this.newUser)
          Auth.createUserWithEmailAndPassword(this.newUser.email, this.newUser.password)
          .then((user) => {
            this.snackbarMessage = 'Logging In.'
              this.snackbar = true
              const FireBaseUsers = Firestore.collection('users')
              // console.log('user', user.user)
              // console.log('uid', user.user.uid)
              if (user.user && user.user.uid) {
                this.newUser.id = user.user.uid
                this.newUser.createdAt = Date()

                FireBaseUsers.doc(user.user.uid)
                  .set(this.newUser)
                  .then(() => {
                    store.dispatch('fetchUser', this.newUser)
                    setTimeout(this.redirectPortal(this.newUser.usertype), 1000)
                  })
              }
          })
          .catch(error => {
            switch (error.code) {
                case 'auth/email-already-in-use':
                  this.snackbarMessage = `Email address ${this.newUser.email} already in use.`
                  this.snackbar = true
                  break
                case 'auth/invalid-email':
                  this.snackbarMessage = `Email address ${this.newUser.email} is invalid.`
                  break
                case 'auth/operation-not-allowed':
                  this.snackbarMessage = 'Error during sign up.'
                  break
                case 'auth/weak-password':
                  this.snackbarMessage = 'Password is not strong enough. Add additional characters including special characters and numbers.'
                  break
                default:
                  console.log(error.message)
                  break
              }
          })
          // const registerNewUser = Functions.httpsCallable('registerNewUser')
          // registerNewUser(this.newUser)
          //   .then(() => {
          //     console.log('user added')
          //     Auth.signInWithEmailAndPassword(this.newUser.email, this.newUser.password).then((user) => {
          //     this.snackbarMessage = 'Logging In.'
          //     this.snackbar = true
          //     const FireBaseUsers = Firestore.collection('users')
          //     // console.log('user', user.user)
          //     // console.log('uid', user.user.uid)
          //     if (user.user && user.user.uid) {
          //       FireBaseUsers.doc(user.user.uid)
          //         .get()
          //         .then((doc) => {
          //           const thisUser = doc.data()
          //           thisUser.id = doc.id
          //           thisUser.displayName = user.user.displayName
          //           thisUser.email = user.user.email
          //           store.dispatch('fetchUser', thisUser)
          //           setTimeout(this.redirectPortal(thisUser.usertype), 1000)
          //         })
          //     }
          //   }).catch(error => {
          //     console.log('error', error)
          //     this.snackbarMessage = 'Error Logging In.'
          //     this.loginError = 'Invalid Email Address/Password'
          //     this.snackbar = true
          //   })
          //   })
          //   .catch(error => {
          //     console.log('error :', error)
          //   })
        }
      },
    },
  }
</script>
